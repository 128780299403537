// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import { Search } from './';
import { EquipmentDetails } from '../equipments';
import EquipmentDetailsBySerial from '../equipments/EquipmentDetailsBySerial';

export default {
  path: '',
  childRoutes: [
    { path: ':manufacturerId/:serial', component: EquipmentDetailsBySerial },
    { path: 'search', component: Search },
    { path: 'search/equipment/:id', component: EquipmentDetails },
    { path: 'search/lat/:latId', component: Search },
  ],
};
