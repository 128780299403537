import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_BEGIN,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_SUCCESS,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_FAILURE,
  EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_DISMISS_ERROR
} from './constants';
import ApiService from "../../common/services/ApiService";

export function searchEquipmentFromMfgSerial(mfgId, serialNumber) {
  return (dispatch) => { // optionally you can have getState as the second argument
    dispatch({
      type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
        ApiService.searchAssetFromMfgSerial(mfgId, serialNumber).then(
        (res) => {
          dispatch({
            type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_SUCCESS,
            data: res,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          dispatch({
            type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_FAILURE,
            data: { error: err },
          });
          // reject(err);
        },
      );
    });

    return promise;
  };
}

export function dismissSearchEquipmentFromMfgSerialError() {
  return {
    type: EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_DISMISS_ERROR,
  };
}

export function useSearchEquipmentFromMfgSerial(params) {
  const dispatch = useDispatch();

  const { searchResultFromMfgSerial, searchEquipmentFromMfgSerialPending, searchEquipmentFromMfgSerialError } = useSelector(
    state => ({
      searchResultFromMfgSerial: state.equipments.searchResultFromMfgSerial,
      searchEquipmentFromMfgSerialPending: state.equipments.searchEquipmentFromMfgSerialPending,
      searchEquipmentFromMfgSerialError: state.equipments.searchEquipmentFromMfgSerialError,
    }),
    shallowEqual,
  );

  const boundAction = useCallback((...args) => {
    return dispatch(searchEquipmentFromMfgSerial(...args));
  }, [dispatch]);

  useEffect(() => {
    if (params) boundAction(...(params || []));
  }, [...(params || []), boundAction]); // eslint-disable-line

  const boundDismissError = useCallback(() => {
    return dispatch(dismissSearchEquipmentFromMfgSerialError());
  }, [dispatch]);

  return {
    searchResultFromMfgSerial,
    searchEquipmentFromMfgSerial: boundAction,
    searchEquipmentFromMfgSerialPending,
    searchEquipmentFromMfgSerialError,
    dismissSearchEquipmentFromMfgSerialError: boundDismissError
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        searchEquipmentFromMfgSerialPending: true,
        searchEquipmentFromMfgSerialError: null,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_SUCCESS:
      // The request is success
      return {
        ...state,
        searchEquipmentFromMfgSerialPending: false,
        searchEquipmentFromMfgSerialError: null,
        searchResultFromMfgSerial: action.data,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_FAILURE:
      // The request is failed
      return {
        ...state,
        searchEquipmentFromMfgSerialPending: false,
        searchEquipmentFromMfgSerialError: action.data.error,
      };

    case EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_DISMISS_ERROR:
      // Dismiss the request failure error
      return {
        ...state,
        searchEquipmentFromMfgSerialError: null,
      };

    default:
      return state;
  }
}
