export const ACTIVTY_SELECTIONS = ['MANUFACTURED', 'SHIPPED', 'STORAGE', 'INSTALLATION', 'MAINTENANCE SCHEDULED', 'MAINTENANCE UNSCHEDULED', 'OWNERSHIP CHANGE', 'DECOMMISION', 'WAREHOUSE ENTRY', 'WAREHOUSE EXIT', 'UNCRATE', 'INSTALL', 'CONNECT', 'WARRANTY WORK', 'SERVICE AGREEMENT WORK'];

export const EQUIPMENTS_GET_MY_EQUIPMENTS_BEGIN = 'EQUIPMENTS_GET_MY_EQUIPMENTS_BEGIN';
export const EQUIPMENTS_GET_MY_EQUIPMENTS_SUCCESS = 'EQUIPMENTS_GET_MY_EQUIPMENTS_SUCCESS';
export const EQUIPMENTS_GET_MY_EQUIPMENTS_FAILURE = 'EQUIPMENTS_GET_MY_EQUIPMENTS_FAILURE';
export const EQUIPMENTS_GET_MY_EQUIPMENTS_DISMISS_ERROR = 'EQUIPMENTS_GET_MY_EQUIPMENTS_DISMISS_ERROR';
export const EQUIPMENTS_GET_EQUIPMENT_DETAILS_BEGIN = 'EQUIPMENTS_GET_EQUIPMENT_DETAILS_BEGIN';
export const EQUIPMENTS_GET_EQUIPMENT_DETAILS_SUCCESS = 'EQUIPMENTS_GET_EQUIPMENT_DETAILS_SUCCESS';
export const EQUIPMENTS_GET_EQUIPMENT_DETAILS_FAILURE = 'EQUIPMENTS_GET_EQUIPMENT_DETAILS_FAILURE';
export const EQUIPMENTS_GET_EQUIPMENT_DETAILS_DISMISS_ERROR = 'EQUIPMENTS_GET_EQUIPMENT_DETAILS_DISMISS_ERROR';
export const EQUIPMENTS_TOGGLE_WARRANTY_DIALOG = 'EQUIPMENTS_TOGGLE_WARRANTY_DIALOG';
export const EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_DIALOG = 'EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_DIALOG';
export const EQUIPMENTS_TOGGLE_ACTIVITY_DETAILS_DIALOG = 'EQUIPMENTS_TOGGLE_ACTIVITY_DETAILS_DIALOG';
export const EQUIPMENTS_UPDATE_WARRANTY_BEGIN = 'EQUIPMENTS_UPDATE_WARRANTY_BEGIN';
export const EQUIPMENTS_UPDATE_WARRANTY_SUCCESS = 'EQUIPMENTS_UPDATE_WARRANTY_SUCCESS';
export const EQUIPMENTS_UPDATE_WARRANTY_FAILURE = 'EQUIPMENTS_UPDATE_WARRANTY_FAILURE';
export const EQUIPMENTS_UPDATE_WARRANTY_DISMISS_ERROR = 'EQUIPMENTS_UPDATE_WARRANTY_DISMISS_ERROR';
export const EQUIPMENTS_GET_ACTIVITY_DETAILS_BEGIN = 'EQUIPMENTS_GET_ACTIVITY_DETAILS_BEGIN';
export const EQUIPMENTS_GET_ACTIVITY_DETAILS_SUCCESS = 'EQUIPMENTS_GET_ACTIVITY_DETAILS_SUCCESS';
export const EQUIPMENTS_GET_ACTIVITY_DETAILS_FAILURE = 'EQUIPMENTS_GET_ACTIVITY_DETAILS_FAILURE';
export const EQUIPMENTS_GET_ACTIVITY_DETAILS_DISMISS_ERROR = 'EQUIPMENTS_GET_ACTIVITY_DETAILS_DISMISS_ERROR';
export const EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_BEGIN = 'EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_BEGIN';
export const EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_SUCCESS = 'EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_SUCCESS';
export const EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_FAILURE = 'EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_FAILURE';
export const EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_DISMISS_ERROR = 'EQUIPMENTS_UPDATE_SERVICE_AGREEMENT_DISMISS_ERROR';
export const EQUIPMENTS_CREATE_EQUIPMENT_BEGIN = 'EQUIPMENTS_CREATE_EQUIPMENT_BEGIN';
export const EQUIPMENTS_CREATE_EQUIPMENT_SUCCESS = 'EQUIPMENTS_CREATE_EQUIPMENT_SUCCESS';
export const EQUIPMENTS_CREATE_EQUIPMENT_FAILURE = 'EQUIPMENTS_CREATE_EQUIPMENT_FAILURE';
export const EQUIPMENTS_CREATE_EQUIPMENT_DISMISS_ERROR = 'EQUIPMENTS_CREATE_EQUIPMENT_DISMISS_ERROR';
export const EQUIPMENTS_CREATE_WARRANTY_BEGIN = 'EQUIPMENTS_CREATE_WARRANTY_BEGIN';
export const EQUIPMENTS_CREATE_WARRANTY_SUCCESS = 'EQUIPMENTS_CREATE_WARRANTY_SUCCESS';
export const EQUIPMENTS_CREATE_WARRANTY_FAILURE = 'EQUIPMENTS_CREATE_WARRANTY_FAILURE';
export const EQUIPMENTS_CREATE_WARRANTY_DISMISS_ERROR = 'EQUIPMENTS_CREATE_WARRANTY_DISMISS_ERROR';
export const EQUIPMENTS_CREATE_SERVICE_AGREEMENT_BEGIN = 'EQUIPMENTS_CREATE_SERVICE_AGREEMENT_BEGIN';
export const EQUIPMENTS_CREATE_SERVICE_AGREEMENT_SUCCESS = 'EQUIPMENTS_CREATE_SERVICE_AGREEMENT_SUCCESS';
export const EQUIPMENTS_CREATE_SERVICE_AGREEMENT_FAILURE = 'EQUIPMENTS_CREATE_SERVICE_AGREEMENT_FAILURE';
export const EQUIPMENTS_CREATE_SERVICE_AGREEMENT_DISMISS_ERROR = 'EQUIPMENTS_CREATE_SERVICE_AGREEMENT_DISMISS_ERROR';
export const EQUIPMENTS_SEARCH_EQUIPMENT_BEGIN = 'EQUIPMENTS_SEARCH_EQUIPMENT_BEGIN';
export const EQUIPMENTS_SEARCH_EQUIPMENT_SUCCESS = 'EQUIPMENTS_SEARCH_EQUIPMENT_SUCCESS';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FAILURE = 'EQUIPMENTS_SEARCH_EQUIPMENT_FAILURE';
export const EQUIPMENTS_SEARCH_EQUIPMENT_DISMISS_ERROR = 'EQUIPMENTS_SEARCH_EQUIPMENT_DISMISS_ERROR';
export const EQUIPMENTS_SEARCH_EQUIPMENT_RESET = 'EQUIPMENTS_SEARCH_EQUIPMENT_RESET';
export const EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_LIST = 'EQUIPMENTS_TOGGLE_SERVICE_AGREEMENT_LIST';
export const EQUIPMENTS_GET_OWNERS_BEGIN = 'EQUIPMENTS_GET_OWNERS_BEGIN';
export const EQUIPMENTS_GET_OWNERS_SUCCESS = 'EQUIPMENTS_GET_OWNERS_SUCCESS';
export const EQUIPMENTS_GET_OWNERS_FAILURE = 'EQUIPMENTS_GET_OWNERS_FAILURE';
export const EQUIPMENTS_GET_OWNERS_DISMISS_ERROR = 'EQUIPMENTS_GET_OWNERS_DISMISS_ERROR';
export const EQUIPMENTS_GET_ACTIVITY_TYPES_BEGIN = 'EQUIPMENTS_GET_ACTIVITY_TYPES_BEGIN';
export const EQUIPMENTS_GET_ACTIVITY_TYPES_SUCCESS = 'EQUIPMENTS_GET_ACTIVITY_TYPES_SUCCESS';
export const EQUIPMENTS_GET_ACTIVITY_TYPES_FAILURE = 'EQUIPMENTS_GET_ACTIVITY_TYPES_FAILURE';
export const EQUIPMENTS_GET_ACTIVITY_TYPES_DISMISS_ERROR = 'EQUIPMENTS_GET_ACTIVITY_TYPES_DISMISS_ERROR';
export const EQUIPMENTS_GET_SHIPPERS_BEGIN = 'EQUIPMENTS_GET_SHIPPERS_BEGIN';
export const EQUIPMENTS_GET_SHIPPERS_SUCCESS = 'EQUIPMENTS_GET_SHIPPERS_SUCCESS';
export const EQUIPMENTS_GET_SHIPPERS_FAILURE = 'EQUIPMENTS_GET_SHIPPERS_FAILURE';
export const EQUIPMENTS_GET_SHIPPERS_DISMISS_ERROR = 'EQUIPMENTS_GET_SHIPPERS_DISMISS_ERROR';
export const EQUIPMENTS_SAVE_ACTIVITY_BEGIN = 'EQUIPMENTS_SAVE_ACTIVITY_BEGIN';
export const EQUIPMENTS_SAVE_ACTIVITY_SUCCESS = 'EQUIPMENTS_SAVE_ACTIVITY_SUCCESS';
export const EQUIPMENTS_SAVE_ACTIVITY_FAILURE = 'EQUIPMENTS_SAVE_ACTIVITY_FAILURE';
export const EQUIPMENTS_SAVE_ACTIVITY_DISMISS_ERROR = 'EQUIPMENTS_SAVE_ACTIVITY_DISMISS_ERROR';
export const EQUIPMENTS_UPDATE_EQUIPMENT_BEGIN = 'EQUIPMENTS_UPDATE_EQUIPMENT_BEGIN';
export const EQUIPMENTS_UPDATE_EQUIPMENT_SUCCESS = 'EQUIPMENTS_UPDATE_EQUIPMENT_SUCCESS';
export const EQUIPMENTS_UPDATE_EQUIPMENT_FAILURE = 'EQUIPMENTS_UPDATE_EQUIPMENT_FAILURE';
export const EQUIPMENTS_UPDATE_EQUIPMENT_DISMISS_ERROR = 'EQUIPMENTS_UPDATE_EQUIPMENT_DISMISS_ERROR';
export const EQUIPMENTS_TOGGLE_ADD_OWNER_DIALOG = 'EQUIPMENTS_TOGGLE_ADD_OWNER_DIALOG';
export const EQUIPMENTS_SAVE_OWNER_BEGIN = 'EQUIPMENTS_SAVE_OWNER_BEGIN';
export const EQUIPMENTS_SAVE_OWNER_SUCCESS = 'EQUIPMENTS_SAVE_OWNER_SUCCESS';
export const EQUIPMENTS_SAVE_OWNER_FAILURE = 'EQUIPMENTS_SAVE_OWNER_FAILURE';
export const EQUIPMENTS_SAVE_OWNER_DISMISS_ERROR = 'EQUIPMENTS_SAVE_OWNER_DISMISS_ERROR';
export const EQUIPMENTS_GET_ASSET_MANUFACTURER_INFO_BEGIN = 'EQUIPMENTS_GET_ASSET_MANUFACTURER_INFO_BEGIN';
export const EQUIPMENTS_GET_ASSET_MANUFACTURER_INFO_SUCCESS = 'EQUIPMENTS_GET_ASSET_MANUFACTURER_INFO_SUCCESS';
export const EQUIPMENTS_GET_ASSET_MANUFACTURER_INFO_FAILURE = 'EQUIPMENTS_GET_ASSET_MANUFACTURER_INFO_FAILURE';
export const EQUIPMENTS_GET_ASSET_MANUFACTURER_INFO_DISMISS_ERROR = 'EQUIPMENTS_GET_ASSET_MANUFACTURER_INFO_DISMISS_ERROR';
export const EQUIPMENTS_GET_ASSET_ACTIVITIES_BEGIN = 'EQUIPMENTS_GET_ASSET_ACTIVITIES_BEGIN';
export const EQUIPMENTS_GET_ASSET_ACTIVITIES_SUCCESS = 'EQUIPMENTS_GET_ASSET_ACTIVITIES_SUCCESS';
export const EQUIPMENTS_GET_ASSET_ACTIVITIES_FAILURE = 'EQUIPMENTS_GET_ASSET_ACTIVITIES_FAILURE';
export const EQUIPMENTS_GET_ASSET_ACTIVITIES_DISMISS_ERROR = 'EQUIPMENTS_GET_ASSET_ACTIVITIES_DISMISS_ERROR';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_BEGIN = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_BEGIN';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_SUCCESS = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_SUCCESS';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_FAILURE = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_FAILURE';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_DISMISS_ERROR = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_QR_CODE_DISMISS_ERROR';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_BEGIN = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_BEGIN';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_SUCCESS = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_SUCCESS';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_FAILURE = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_FAILURE';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_DISMISS_ERROR = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_LAT_DISMISS_ERROR';

export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_BEGIN = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_BEGIN';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_SUCCESS = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_SUCCESS';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_FAILURE = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_FAILURE';
export const EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_DISMISS_ERROR = 'EQUIPMENTS_SEARCH_EQUIPMENT_FROM_MFG_SERIAL_DISMISS_ERROR';

export const EQUIPMENTS_TOGGLE_ACTIVITY_IMAGE_DIALOG = 'EQUIPMENTS_TOGGLE_ACTIVITY_IMAGE_DIALOG';
export const EQUIPMENTS_GET_ASSETS_ADDRESS_BY_USER_BEGIN = 'EQUIPMENTS_GET_ASSETS_ADDRESS_BY_USER_BEGIN';
export const EQUIPMENTS_GET_ASSETS_ADDRESS_BY_USER_SUCCESS = 'EQUIPMENTS_GET_ASSETS_ADDRESS_BY_USER_SUCCESS';
export const EQUIPMENTS_GET_ASSETS_ADDRESS_BY_USER_FAILURE = 'EQUIPMENTS_GET_ASSETS_ADDRESS_BY_USER_FAILURE';
export const EQUIPMENTS_GET_ASSETS_ADDRESS_BY_USER_DISMISS_ERROR = 'EQUIPMENTS_GET_ASSETS_ADDRESS_BY_USER_DISMISS_ERROR';
