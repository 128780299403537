// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  getMyEquipmentsPending: false,
  getMyEquipmentsError: null,
  getEquipmentDetailsPending: false,
  getEquipmentDetailsError: null,

  isWarrantyDialogVisible: false,
  isServiceAgreementDialogVisible: false,
  activityDetails: {},
  updateWarrantyPending: false,
  updateWarrantyError: null,
  getActivityDetailsPending: false,
  getActivityDetailsError: null,
  updateServiceAgreementPending: false,
  updateServiceAgreementError: null,
  createEquipmentPending: false,
  createEquipmentError: null,
  createdEquipment: null,
  // createdEquipment: {"assetId":11,"manufacturerId":1,"partId":8,"serialNumber":"2342423","notes":"dasd","addressId":32,"ownerId":1,"parentAssetId":null,"manufactureDate":"2020-06-20T16:00:00.000Z","createdAt":"2020-06-21T07:40:50.000Z","address":{"addressId":32,"line1":"dsadadasd","line2":"","city":"cebu","state":"Georgia","zipCode":"23453534","country":"United States","createdAt":"2020-06-21T07:40:50.000Z"},"manufacturer":{"manufacturerId":1,"description":"Test3","addressId":4,"createdAt":"2020-04-20T18:22:44.000Z"},"part":{"partId":8,"manufacturerId":1,"description":"Kitchen Desk","createdAt":"2020-04-20T18:27:05.000Z"},"owner":{"ownerId":1,"company":"NeighborsSoft4","addressId":2,"createdAt":"2020-04-20T18:21:17.000Z","address":{"addressId":2,"line1":"Placheho 8","line2":"","city":"Plzen","state":"Plzensky Kraj","zipCode":"2192","country":"Czech Republic","createdAt":"2020-04-20T18:21:39.000Z"}},"activity":{"activityId":14,"addressId":32,"date":"2020-06-20T16:00:00.000Z","notes":"Asset Created.","activityTypeId":1,"assetId":11,"authorizedBy":2,"createdAt":"2020-06-21T07:40:50.000Z","address":{"addressId":32,"line1":"dsadadasd","line2":"","city":"cebu","state":"Georgia","zipCode":"23453534","country":"United States","createdAt":"2020-06-21T07:40:50.000Z"},"activtyType":{"activityTypeId":1,"description":"Manufactured"},"user":{"userId":2,"firstName":"Thomasz","lastName":"Bereczky","email":"gandalf@sysopsnet.com","ownerId":1,"manufacturerId":1,"phoneNumber":"774401137","roleId":1,"title":"Mr.","createdAt":"2020-04-20T17:59:12.000Z","updatedAt":"2020-06-20T14:32:39.000Z","owner":{"ownerId":1,"company":"NeighborsSoft4","addressId":2,"createdAt":"2020-04-20T18:21:17.000Z","address":{"addressId":2,"line1":"Placheho 8","line2":"","city":"Plzen","state":"Plzensky Kraj","zipCode":"2192","country":"Czech Republic","createdAt":"2020-04-20T18:21:39.000Z"}}}}},
  createWarrantyPending: false,
  createWarrantyError: null,
  createServiceAgreementPending: false,
  createServiceAgreementError: null,
  searchEquipmentPending: false,
  searchEquipmentError: null,
  searchEquipmentFromQRCodePending: false,
  searchEquipmentFromQRCodeError: null,
  searchEquipmentFromLatPending: false,
  searchEquipmentFromLatError: null,
  searchEquipmentFromMfgSerialPending: false,
  searchEquipmentFromMfgSerialError: false,
  getOwnersPending: false,
  getOwnersError: null,
  getActivityTypesPending: false,
  getActivityTypesError: null,
  getShippersPending: false,
  getShippersError: null,
  saveActivityPending: false,
  saveActivityError: null,
  updateEquipmentPending: false,
  updateEquipmentError: null,
  updatedEquipment: null,
  saveOwnerPending: false,
  saveOwnerError: null,
  newOwners: [],
  assetManufacturerInfo: {},
  getAssetManufacturerInfoPending: false,
  getAssetManufacturerInfoError: null,
  assetActivities: {},
  getAssetActivitiesPending: false,
  getAssetActivitiesError: null,

  newActivityAddedTimestamp: null,
  isActivityImageDialogVisible: false,
  activityImage: null,
  activityFiles: [],
  getAssetsAddressByUserPending: false,
  getAssetsAddressByUserError: null,
};

export default initialState;
